/**
 * Account actions
 */
export const ACCOUNT_LOGGED_IN = "ACCOUNT_LOGGED_IN";
export const ACCOUNT_LOGGED_OUT = "ACCOUNT_LOGGED_OUT";

export function accountLoggedIn(value) {
  return { type: ACCOUNT_LOGGED_IN, value };
}

export function accountLoggedOut() {
  return { type: ACCOUNT_LOGGED_OUT };
}

export const ACCOUNT_SET = "ACCOUNT_SET";
export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
export const ACCOUNT_UPDATE_SETTINGS = "ACCOUNT_UPDATE_SETTINGS";
export const ACCOUNT_REMOVE = "ACCOUNT_REMOVE";

export function accountSet(name, value) {
  return { type: ACCOUNT_SET, name, value };
}

export function accountUpdate(name, value) {
  return { type: ACCOUNT_UPDATE, name, value };
}

export function accountUpdateSettings(name, value) {
  return { type: ACCOUNT_UPDATE_SETTINGS, name, value };
}

export function accountRemove(name) {
  return { type: ACCOUNT_REMOVE, name };
}

/**
 * Account Access key actions
 */
export const ACCOUNT_ACCESS_KEY_SET = "ACCOUNT_ACCOUNT_ACCESS_KEY_SET";
export const ACCOUNT_ACCESS_KEY_REMOVE = "ACCOUNT_ACCOUNT_ACCESS_KEY_REMOVE";

export function accountAccessKeySet(value) {
  return { type: ACCOUNT_ACCESS_KEY_SET, value };
}

export function accountAccessKeyRemove() {
  return { type: ACCOUNT_ACCESS_KEY_REMOVE };
}
