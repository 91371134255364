import React, { Component } from "react";
import { ApiAccountService } from "../../services/api/account.service";
import Spinner from "../reactstrap/Spinner";
import Scrollable from "../Scrollable";

export default class ConnectionRequestOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      data: [],
      loading: true,
    };
  }

  componentWillMount() {
    this.getConnectionRequests();
  }

  getConnectionRequests() {
    ApiAccountService.getConnectionRequests()
      .then(response => {
        this.setState({ status: response.status, data: response.data ? response.data.groups : [], loading: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    if (!this.state.data.length > 0) {
      return (
        <p className="text-muted m-3">
          Momenteel heeft u geen openstaande aanvragen.
        </p>
      );
    }

    return (
      <Scrollable height="300" className="list-group">
        {this.state.data.length > 0 && (
          <table
            className="table table-borderedless"
            style={{ tableLayout: "fixed" }}
          >
            <tbody>
              {this.state.data.map((cr, i) => (
                <tr
                  key={i}
                  style={{
                    backgroundColor: "#e2edff",
                    marginBottom: "10px",
                    marginTop: "10px",
                    borderRadius: "7px",
                  }}
                  className="d-flex justify-content-start"
                >
                  <td style={{ border: "none" }} className="w-25">
                    <strong>
                      <p>{cr.name}</p>
                    </strong>
                    {cr.reviewed === "0" && cr.accepted === "0" && (
                      <div
                        className="p-1 bg-warning text-center d-inline-block"
                        style={{ border: "none", borderRadius: "7px" }}
                      >
                        <span className="p-0">In afwachting</span>
                      </div>
                    )}
                    {cr.reviewed === "1" && cr.accepted === "0" && (
                      <div
                        className="p-1 bg-danger text-center d-inline-block"
                        style={{ border: "none", borderRadius: "7px" }}
                      >
                        <span>Geweigerd</span>
                      </div>
                    )}
                  </td>
                  <td className="w-25">
                    <p>{cr.address + ", " + cr.postal_code + " " + cr.city}</p>
                    <p>{cr.phone}</p>
                    <p>{cr.email}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Scrollable>
    );
  }
}
