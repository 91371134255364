import { API } from "../../services/api";
import {
  ACCOUNT_LOGGED_IN,
  ACCOUNT_LOGGED_OUT,
  ACCOUNT_ACCESS_KEY_REMOVE,
} from "./account.actions";

/*
   Hook into setting changes in order to change layout.
*/
const account = (store) => (next) => (action) => {
  let result = next(action);

  if (action.type === ACCOUNT_LOGGED_IN) {
    API.setToken(action.value.access_key);
  } else if (
    action.type === ACCOUNT_LOGGED_OUT ||
    action.type === ACCOUNT_ACCESS_KEY_REMOVE
  ) {
    API.setToken(null);
  }

  return result;
};

export default account;
