import React, { Component } from "react";
import moment from "moment";
import { Table } from "reactstrap";

class EidDataTable extends Component {
  render() {
    const { eidData, eid } = this.props;

    return (
      <Table bordered style={{ backgroundColor: "#edf2f9" }}>
        <tbody>
          <tr>
            <td colSpan="2" className="text-center">
              <img src={`data:image/png;base64,${eidData.imageFile}`}  alt="eid" />
            </td>
          </tr>
          <tr>
            <td className="text-center">Voornaam: {eidData.firstName}</td>
            <td className="text-center">Achternaam: {eidData.lastName}</td>
          </tr>
          <tr>
            <td className="text-center">
              Geslacht: {eid === "true" ? eidData.sex : eidData.gender}
            </td>
            <td className="text-center">
              Geboortedatum:{" "}
              {eid === "true"
                ? eidData.dateOfBirth
                : moment(eidData.birthdate, "YYYY-MM-DD").format(
                  "DD MMM YYYY"
                )}
            </td>
          </tr>
          <tr>
            <td className="text-center">Adres: {eidData.street}</td>
            <td className="text-center">
              Postcode: {eid === "true" ? eidData.zipcode : eidData.postalCode}
            </td>
          </tr>
          <tr>
            <td className="text-center">Stad/gemeente: {eidData.city}</td>
            <td className="text-center">
              Nationaliteit:{" "}
              {eid === "true" ? eidData.country : eidData.nationality}
            </td>
          </tr>
          <tr>
            <td className="text-center" colSpan="2">
              Rijksregisternummer:{" "}
              {eid === "true"
                ? eidData.nationalNumber
                : eidData.nationalInsuranceNumber}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default EidDataTable;
