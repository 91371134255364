import React, { Component } from "react";
import { Button, CardBody, CardHeader } from "reactstrap";
import { ApiAccountService } from "../../services/api/account.service";
import DropDownEidHelp from "../dropdown/DropDownEidHelp";
import EidDataTable from "../Eid/EidDataTable";
import ContentWrapper from "../layout/ContentWrapper";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      accountData: null,
      cardData: null,
      status: "",
      cardStatus: "",
      updateStatus: "",
      errors: [],
      userId: "",
      matches: window.matchMedia("(min-width: 768px)").matches,
    };
  }

  componentDidMount() {
    let userId = null;
    if (this.props.history.location.state) {
      userId = this.props.history.location.state.userId;
      this.setState({ userId: userId });
    }

    this.getAccountInfo(userId);

    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addEventListener("change", handler);
  }

  getAccountInfo = (id = null) => {
    ApiAccountService.getAccountInfo(id)
      .then(response => {
        this.setState({
          accountData: response.data ? response.data.account : null,
          status: response.status,
        });
      });
  }

  updateAccountInfo = () => {
    if (this.state.status !== "success") {
      return;
    }

    ApiAccountService.updateAccountInfo(
      this.state.cardData,
      this.state.userId
    ).then(response => {
      if (response.status === "fail") {
        this.setState({
          updateStatus: response.status,
          errors: response.data,
        });
      }
    });
  }

  showToast() {
    if (this.state.cardStatus === "success") {
      toast("Gegevens succesvol bijgewerkt.", {
        type: "success",
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (this.state.cardStatus === "failed") {
      toast("Geen kaart gevonden", {
        type: "error",
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (this.state.updateStatus === "fail") {
      toast(this.state.errors[0], {
        type: "error",
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  getEidData = () => {
    this.setState({ loading: true });

    ApiAccountService.readEid()
      .then(response => {
        if (!response) throw t('common:errorMessage');

        this.setState({ cardData: response.data, cardStatus: response.status });

        if (response.status === "success") {
          this.updateAccountInfo();
        }
      })
      .catch(error => {
        this.setState({
          updateStatus: 'fail',
          errors: [t('common:errorMessage')]
        });
      })
      .then(() => {
        this.setState({ loading: false });
        this.showToast();
      })
  }

  render() {
    let width = "65%";
    if (!this.state.matches) {
      width = "100%";
    }

    console.log('test', this.state.accountData);
    return (
      <ContentWrapper>
        <div style={{ width: width }}>
          <CardHeader>
            <span className="h4 m-0">Gegevens</span>
          </CardHeader>
          <CardBody>
            <p>
              Gegevens niet meer up-to-date? U kunt uw gegevens updaten door uw{" "}
              <strong>eID aan te sluiten</strong> en vervolgens op "Gegevens
              bijwerken" te klikken.
            </p>
            <DropDownEidHelp />
            <div className="d-flex">
              <div className="p-3">
                {this.state.accountData && (
                  <div className="text-center">
                    {this.state.cardStatus === "success" && (
                      <p className="h4">Oude gegevens</p>
                    )}

                    <EidDataTable eidData={this.state.accountData} />
                    {(this.state.cardStatus === "" ||
                      this.state.cardStatus === "failed") && (
                        <>
                          <Button
                            onClick={this.getEidData}
                            color="primary"
                            disabled={this.state.loading}
                          >
                            <div className="d-flex align-items-center">
                              <em className="fa fa-id-card mr-2" />
                              <span>Gegevens bijwerken</span>
                            </div>
                          </Button>
                        </>
                      )}
                  </div>
                )}
              </div>
              {this.state.cardStatus === "success" && (
                <div className="p-3">
                  <div className="text-center">
                    {this.state.cardStatus === "success" && (
                      <p className="h4">Nieuwe gegevens</p>
                    )}

                    <EidDataTable eidData={this.state.cardData} eid="true" />
                  </div>
                </div>
              )}
              <div />
            </div>
          </CardBody>
        </div>
      </ContentWrapper>
    );
  }
}

export default Profile;
