import { t } from "i18next";
import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ApiAccountService } from "../../services/api/account.service";
import Spinner from "../reactstrap/Spinner";
import Scrollable from "../Scrollable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class ConnectedGroupsOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      data: [],
      loading: true,
      modalOpen: false,
      selectedConnection: null,
    };
  }

  componentWillMount() {
    this.getConnectedGroups();
  }

  getConnectedGroups = () => {
    ApiAccountService.getConnectedGroups()
      .then(response => {
        this.setState({ status: response.status, data: response.data ? response.data.groups : [], loading: false });
      });
  }

  async deleteConnection() {
    await ApiAccountService.deleteConnection(
      this.state.selectedConnection
    ).then((res) => {
      if (res.status === "success") {
        this.setState({ modalOpen: false });
        toast("Connectie succesvol verwijderd", {
          type: "success",
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }

  showModal($connection) {
    this.setState({ modalOpen: true, selectedConnection: $connection.id });
  }

  hideModal() {
    this.setState({ modalOpen: false });
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    if (!this.state.data.length > 0) {
      return (
        <>
          <p className="text-muted m-3">Nog geen gekoppelde praktijken.</p>
          <p className="text-muted m-3">
            Klik op "Praktijk toevoegen" om een praktijk te koppelen.
          </p>
        </>
      );
    }
    return (
      <Scrollable height="300" className="list-group">
        {this.state.data.length > 0 && (
          <table
            className="table table-borderedless"
            style={{ tableLayout: "fixed" }}
          >
            <tbody>
              {this.state.data.map((connection, i) => (
                <tr
                  key={i}
                  style={{
                    backgroundColor: "#e2edff",
                    marginBottom: "10px",
                    marginTop: "10px",
                    borderRadius: "7px",
                  }}
                  className="d-flex justify-content-start"
                >
                  <td style={{ border: "none" }} className="w-25">
                    <strong>
                      <p>{connection.name}</p>
                    </strong>
                  </td>
                  <td style={{ border: "none" }} className="w-25">
                    <p>
                      {connection.address +
                        ", " +
                        connection.postal_code +
                        " " +
                        connection.city}
                    </p>
                    <p>{connection.phone}</p>
                    <p>{connection.email}</p>
                    <p />
                    <div>
                      <em className="fa fa-user mr-2" />
                      <span>
                        {connection.first_name + " " + connection.last_name}
                      </span>
                    </div>
                  </td>
                  <td
                    style={{ border: "none" }}
                    className="flex-grow-1 text-right w-50 "
                  >
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="mr-3">
                        {connection.patients_can_book_appointment === "1" && (
                          <button className="btn btn-primary" href="/">
                            Boek een afspraak
                          </button>
                        )}
                      </div>
                      <div>
                        <Button
                          color="danger"
                          type="button"
                          onClick={() => this.showModal(connection)}
                        >
                          <em className="fa fa-trash" />
                        </Button>
                        <Modal isOpen={this.state.modalOpen}>
                          <ModalHeader>
                            <strong>Connectie verwijderen</strong>
                          </ModalHeader>
                          <ModalBody>
                            Bent u zeker dat u deze connectie wilt verwijderen?
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="default"
                              onClick={() => this.hideModal()}
                            >
                              {t("common:Cancel")}
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => this.deleteConnection()}
                            >
                              {t("common:Confirm")}
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Scrollable>
    );
  }
}

export default ConnectedGroupsOverview;
