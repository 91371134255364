import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Collapse,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Scrollable from "../Scrollable";

class DropDownEidHelp extends Component {
  constructor(props) {
    super(props);

    this.state = { dropdownOpen: false, accordionState: [false, false, false] };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  toggleAccordion = (id) => {
    let accordionState = this.state.accordionState.map((val, i) => {
      return id === i ? !val : this.state.oneAtATime ? false : val;
    });
    this.setState({
      accordionState,
    });
  };

  render() {
    return (
      <>
        <div>
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle color="link" className="p-0">
              <em className="fas fa-question-circle mr-2" />
              <span>Hulp nodig met eID?</span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-right-forced animated fadeInUpShort w-100">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <CardTitle tag="h4" className="m-0">
                      Hulp bij eID inlezen
                    </CardTitle>
                  </div>
                  <div>
                    <em
                      className="fa fa-times p-2"
                      style={{ cursor: "pointer" }}
                      onClick={this.toggle}
                    />
                  </div>
                </div>
              </CardHeader>
              <Scrollable height="300">
                <Card className="b0 mb-2">
                  <CardHeader onClick={() => this.toggleAccordion(0)}>
                    <CardTitle tag="h4">
                      <a className="text-inherit">
                        <small>
                          <em className="fa fa-plus text-primary mr-2" />
                        </small>
                        <span className="text-link">
                          Hoe kan ik mijn eID inlezen?
                        </span>
                      </a>
                    </CardTitle>
                  </CardHeader>
                  <Collapse isOpen={this.state.accordionState[0]}>
                    <CardBody>
                      <p>
                        Om een de Belgische Identiteitskaart in te lezen moet u
                        beschikken over:
                      </p>
                      <ul>
                        <li>
                          Een kaartlezer die verbonden is met uw computer.
                        </li>
                        <li>
                          De{" "}
                          <a
                            href="https://eid.belgium.be/nl"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            eID Software
                          </a>
                          .
                        </li>
                        <li>
                          Bij sommige acties is de PIN-code van de eID vereist
                        </li>
                      </ul>
                    </CardBody>
                  </Collapse>
                </Card>
                <hr className="m-0" />
                <Card className="b0 mb-2">
                  <CardHeader onClick={() => this.toggleAccordion(1)}>
                    <CardTitle tag="h4">
                      <a className="text-inherit">
                        <small>
                          <em className="fa fa-plus text-primary mr-2" />
                        </small>
                        <span className="text-link">
                          Waarom moet ik mijn eID inlezen?
                        </span>
                      </a>
                    </CardTitle>
                  </CardHeader>
                  <Collapse isOpen={this.state.accordionState[1]}>
                    <CardBody>
                      <p>
                        De Belgische eID uitlezen is een manier om online de
                        identiteit van een persoon te verifiëren. Aangezien er
                        in in het Patiëntenportaal persoonlijke gegevens
                        geraadpleegd kunnen worden, is het nodig om de
                        identiteit van de gebruikers te verifiëren.
                      </p>
                    </CardBody>
                  </Collapse>
                </Card>
                <hr className="m-0" />
                <Card className="b0 mb-2">
                  <CardHeader onClick={() => this.toggleAccordion(2)}>
                    <CardTitle tag="h4">
                      <a className="text-inherit">
                        <small>
                          <em className="fa fa-plus text-primary mr-2" />
                        </small>
                        <span className="text-link">
                          Wat is de eID software?
                        </span>
                      </a>
                    </CardTitle>
                  </CardHeader>
                  <Collapse isOpen={this.state.accordionState[2]}>
                    <CardBody>
                      <p>
                        De eID software maakt het mogelijk voor een computer om
                        een Belgische eID kaart in te lezen via een kaartlezer.
                        Indien deze software niet geïnstalleerd is, zal uw
                        computer de eID niet kunnen uitlezen.
                      </p>
                      <p>
                        Klik{" "}
                        <a
                          href="https://eid.belgium.be/nl"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          hier
                        </a>{" "}
                        om de eID Software te downloaden.
                      </p>
                      <p>
                        Voor meer informatie over de eID software kan u terecht
                        op{" "}
                        <a
                          href="https://eid.belgium.be/nl"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          eid.belgium.be
                        </a>
                        .
                      </p>
                    </CardBody>
                  </Collapse>
                </Card>
                <hr className="m-0" />
              </Scrollable>
            </DropdownMenu>
          </Dropdown>
        </div>
      </>
    );
  }
}

export default DropDownEidHelp;
