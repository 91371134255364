import {
  ACCOUNT_SET,
  ACCOUNT_UPDATE,
  ACCOUNT_UPDATE_SETTINGS,
  ACCOUNT_REMOVE,
  ACCOUNT_ACCESS_KEY_SET,
  ACCOUNT_ACCESS_KEY_REMOVE,
  ACCOUNT_LOGGED_IN,
  ACCOUNT_LOGGED_OUT,
} from "./account.actions";

const accountReducer = (state = null, action) => {
  switch (action.type) {
    case ACCOUNT_LOGGED_IN:
      return action.value;
    case ACCOUNT_LOGGED_OUT:
      return null;
    case ACCOUNT_SET:
    case ACCOUNT_UPDATE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case ACCOUNT_REMOVE:
      return {
        ...state,
        [action.name]: undefined,
      };
    case ACCOUNT_UPDATE_SETTINGS:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          settings: action.value,
        },
      };
    case ACCOUNT_ACCESS_KEY_SET:
      return {
        ...state,
        access_key: action.value,
      };
    case ACCOUNT_ACCESS_KEY_REMOVE:
      return {
        ...state,
        access_key: undefined,
      };
    default:
      return state;
  }
};

export default accountReducer;
