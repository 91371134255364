import React, { Component } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroupItem,
  UncontrolledDropdown,
} from "reactstrap";
import routes from "../../routing/routes";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { UtilUrl } from "../../utils/url.util";
import { Link } from "react-router-dom";

class DropdownProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  render() {
    return (
      <UncontrolledDropdown nav inNavbar className="dropdown-list">
        <DropdownToggle nav className="dropdown-toggle-nocaret">
          <em className="icon-user mr-1" />
          <span>Mijn profiel</span>
        </DropdownToggle>
        {/* START Dropdown menu */}
        <DropdownMenu right className="dropdown-menu-right animated fadeIn">
          <DropdownItem>
            <ListGroupItem
              action
              tag={Link}
              to={{ pathname: UtilUrl.generate(routes.connectuser) }}
            >
              <div className="media">
                <div className="align-self-start mr-2">
                  <em width="20px" className="fa fa-user-plus mr-1" />
                </div>
                <div className="media-body">
                  <p className="m-0">Gebruiker toevoegen</p>
                </div>
              </div>
            </ListGroupItem>
            <ListGroupItem
              action
              tag={Link}
              to={{ pathname: UtilUrl.generate(routes.usersoverview) }}
            >
              <div className="media">
                <div className="align-self-start mr-2">
                  <em width="20px" className="fa fa-users mr-1" />
                </div>
                <div className="media-body">
                  <p className="m-0">Gekoppelde gebruikers</p>
                </div>
              </div>
            </ListGroupItem>
            <ListGroupItem
              action
              tag={Link}
              to={{ pathname: UtilUrl.generate(routes.profile) }}
            >
              <div className="media">
                <div className="align-self-start mr-2">
                  <em width="20px" className="fas fa-address-card mr-1" />
                </div>
                <div className="media-body">
                  <p className="m-0">Mijn gegevens</p>
                </div>
              </div>
            </ListGroupItem>
          </DropdownItem>
        </DropdownMenu>
        {/* END Dropdown menu */}
      </UncontrolledDropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  account: state.account,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownProfile);
