import React, { Component } from "react";
import AppointmentTable from "../../components/Appointments/AppointmentTable";
import { CardBody, CardHeader } from "reactstrap";
import ContentWrapper from "../../components/layout/ContentWrapper";
import moment from "moment";

class AppointmentOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia("(min-width: 768px)").matches
    };
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addEventListener("change", handler);
  }

  render() {
    let width = "65%";
    if (!this.state.matches) {
      width = "100%";
    }

    return (
      <ContentWrapper>
        <div className="d-flex justify-content-between">
          <div style={{ width: width }}>
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <span className="h4  m-0">Mijn afspraken </span>
                <span>{moment().format("dddd DD MMMM YYYY")}</span>
              </div>
            </CardHeader>
            <CardBody>
              <AppointmentTable height="600" />
            </CardBody>
          </div>
        </div>
      </ContentWrapper>
    );
  }
}

export default AppointmentOverview;
