import React, { Component } from "react";
import ContentWrapper from "../../components/layout/ContentWrapper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions";
import NewConnectionRequest from "../../components/ConnectionRequest/NewConnectionRequestModal";
import ConnectionRequestOverview from "../../components/ConnectionRequest/ConnectionRequestOverview";
import { CardBody, CardHeader } from "reactstrap";
import ConnectedGroupsOverview from "../../components/ConnectedGroups/ConnectedGroupsOverview";

class GroupOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { matches: window.matchMedia("(min-width: 768px)").matches };
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addEventListener("change", handler);
  }

  render() {
    let width = "65%";
    if (!this.state.matches) {
      width = "100%";
    }
    return (
      <ContentWrapper>
        <div style={{ width: width }}>
          <CardHeader className="d-flex justify-content-between align-items-center p-2">
            <span className="h4 m-0">Overzicht gekoppelde praktijken</span>
            <NewConnectionRequest />
          </CardHeader>
          <CardBody>
            <ConnectedGroupsOverview />
          </CardBody>
          <CardHeader>
            <span className="h4 m-0">Overzicht huidige aanvragen</span>
          </CardHeader>
          <CardBody>
            <ConnectionRequestOverview />
          </CardBody>
        </div>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  account: state.account,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupOverview);
