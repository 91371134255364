import { include } from "named-urls";

export default {
  notfound: "/404-not-found",
  error: "/500-error",
  welcome: "/",
  groupoverview: "/praktijken",
  appointmentoverview: "/afspraken",
  usersoverview: "/gekoppelde-gebruikers",
  connectuser: "/gebruiker-koppelen",
  profile: "/profiel",

  auth: include("/auth", {
    login: "login",
    forgotpassword: "forgot-password",
    resetpassword: "reset-password/:code",
    register: "register",
    registerinfo: "register-info/:code",
  }),
};
