import PrivateRoute from "./routing/PrivateRoute";
import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

/* loader component for Suspense*/
import PageLoader from "./components/PageLoader";

import Base from "./components/layout/Base";
import BasePage from "./components/layout/BasePage";

import routes from "./routing/routes";
import ErrorBoundary from "./containers/Error/ErrorBoundary";
import componentLoader from "./ComponentLoader";
import GroupOverview from "./containers/Account/GroupOverview";
import AppointmentOverview from "./containers/Account/AppointmentOverview";
import Profile from "./components/Settings/Profile";

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

// Authentication
const Login = lazy(() =>
  componentLoader(() => import("./containers/Pages/Login"))
);
const Register = lazy(() =>
  componentLoader(() => import("./containers/Pages/Register"))
);
const ForgotPassword = lazy(() =>
  componentLoader(() => import("./containers/Pages/ForgotPassword"))
);
const ResetPassword = lazy(() =>
  componentLoader(() => import("./containers/Pages/ResetPassword"))
);

const NotFound = lazy(() =>
  componentLoader(() => import("./containers/Pages/NotFound"))
);
const ErrorPage = lazy(() =>
  componentLoader(() => import("./containers/Pages/Error500"))
);

const Welcome = lazy(() =>
  componentLoader(() => import("./containers/Pages/Welcome"))
);
const RegisterInfo = lazy(() =>
  componentLoader(() => import("./containers/Pages/RegisterInfo"))
);

const UsersOverview = lazy(() =>
  componentLoader(() => import("./containers/Account/UsersOverview"))
);

const ConnectUserPage = lazy(() =>
  componentLoader(() => import("./containers/Account/ConnectUserPage"))
);

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };
  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'
  const animationName = ""; // 'rag-fadeIn'
  if (location.pathname.includes("/auth/") === true) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            {/* See full project for reference */}
            <Route
              exact
              path={routes.auth.forgotpassword}
              component={waitFor(ForgotPassword)}
            />
            <Route
              exact
              path={routes.auth.resetpassword}
              component={waitFor(ResetPassword)}
            />
            <Route exact path={routes.auth.login} component={waitFor(Login)} />
            <Route
              exact
              path={routes.auth.register}
              component={waitFor(Register)}
            />
            <Route
              exact
              path={routes.auth.registerinfo}
              component={waitFor(RegisterInfo)}
            />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      <Base>
        <ErrorBoundary>
          <TransitionGroup>
            <CSSTransition
              key={currentKey}
              timeout={timeout}
              classNames={animationName}
              exit={false}
            >
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  <PrivateRoute
                    exact
                    path={routes.welcome}
                    component={waitFor(Welcome)}
                  />
                  <PrivateRoute
                    exact
                    path={routes.groupoverview}
                    component={waitFor(GroupOverview)}
                  />
                  <PrivateRoute
                    exact
                    path={routes.appointmentoverview}
                    component={waitFor(AppointmentOverview)}
                  />
                  <PrivateRoute
                    exact
                    path={routes.usersoverview}
                    component={waitFor(UsersOverview)}
                  />
                  <PrivateRoute
                    exact
                    path={routes.connectuser}
                    component={waitFor(ConnectUserPage)}
                  />

                  <PrivateRoute
                    exact
                    path={routes.profile}
                    component={waitFor(Profile)}
                  />
                  <Route path={routes.error} component={waitFor(ErrorPage)} />
                  <Route path={routes.notfound} component={waitFor(NotFound)} />

                  <Redirect to={routes.notfound} />
                </Switch>
              </Suspense>
            </CSSTransition>
          </TransitionGroup>
        </ErrorBoundary>
      </Base>
    );
  }
};

export default withRouter(Routes);
