import React, { Component } from "react";
import { ApiAccountService } from "../../services/api/account.service";
import Scrollable from "../Scrollable";
import moment from "moment";
import Spinner from "../reactstrap/Spinner";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions";

class AppointmentTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      linkedStatus: "",
      data: [],
      linkedData: [],
      loading: true,
      linkedLoading: true,
    };
    console.log(this.props);
  }

  componentWillMount() {
    this.getAppointments();
  }

  getAppointments = () => {
    ApiAccountService.getAppointments()
      .then(response => {
        this.setState({
          status: response.status,
          data: response.data,
          loading: false,
        });
      });

    ApiAccountService.getLinkedPatientAppointments()
      .then(response => {
        this.setState({
          linkedStatus: response.status,
          linkedData: response.data,
          linkedLoading: false,
        });
      });

    console.log(this.state);
  }
  render() {
    const { height } = this.props;

    if (this.state.loading || this.state.linkedLoading) {
      return <Spinner />;
    }

    if (!this.state.data.length > 0 && !this.state.linkedData.length > 0) {
      return (
        <>
          <p className="text-muted m-3 text-center">
            U heeft momenteel geen geplande afspraken bij de praktijken waar uw
            account mee gekoppeld is.
          </p>
          <p className="text-muted m-3 text-center">
            Onder "<a href="/praktijken">Mijn praktijken</a>" kunt u een
            aanvraag indienen om een praktijk te koppelen aan uw account.
          </p>
        </>
      );
    }

    return (
      <Scrollable height={height} className="list-group">
        {this.state.data.length > 0 && (
          <>
            <div className="mb-2 ml-1">
              <em className="fa fa-user mr-2" />
              <span className="h4">
                {this.state.data[0].first_name} {this.state.data[0].last_name}
              </span>
            </div>
            <table
              className="table table-borderedless"
              style={{ tableLayout: "fixed" }}
            >
              <tbody>
                {this.state.data
                  .sort((a, b) => (a.start > b.start ? 1 : -1))
                  .map((appointment, i) => (
                    <tr
                      key={i}
                      style={{
                        backgroundColor: "#e2edff",
                        marginBottom: "10px",
                        borderRadius: "7px",
                      }}
                      className="d-flex justify-content-start"
                    >
                      <td style={{ border: "none" }} className="w-50">
                        <strong>
                          <p>
                            {moment(
                              appointment.start,
                              "YYYY-MM-DD hh:mm:ss"
                            ).format("dddd DD MMMM YYYY")}{" "}
                          </p>
                          <p>
                            {moment(
                              appointment.start,
                              "YYYY-MM-DD hh:mm:ss"
                            ).format("H:mm")}
                          </p>
                        </strong>
                      </td>
                      <td style={{ border: "none" }} className="w-25">

                      </td>
                      <td style={{ border: "none" }} className="w-25">
                        <p className="font-weight-bold">{appointment.name}</p>
                        <p>
                          {appointment.address +
                            " " +
                            appointment.postal_code +
                            " " +
                            appointment.city}
                        </p>
                        <p>{appointment.phone}</p>
                        <p>{appointment.email}</p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
        {this.state.linkedData.length > 0 &&
          this.state.linkedData
            .sort((a, b) => (a.start > b.start ? 1 : -1))
            .map((appointment, j) => (
              <>
                <div className="mb-2 ml-1">
                  <em className="fa fa-user mr-2" />
                  <span className="h4">
                    {appointment.first_name + " " + appointment.last_name}
                  </span>
                </div>
                <table
                  className="table table-borderedless"
                  style={{ tableLayout: "fixed" }}
                >
                  <tbody>
                    <tr
                      key={j}
                      style={{
                        backgroundColor: "#e2edff",
                        marginBottom: "10px",
                        borderRadius: "7px",
                      }}
                      className="d-flex justify-content-start"
                    >
                      <td style={{ border: "none" }} className="w-50">
                        <strong>
                          <p>
                            {moment(
                              appointment.start,
                              "YYYY-MM-DD hh:mm:ss"
                            ).format("dddd DD MMMM YYYY")}{" "}
                          </p>
                          <p>
                            {moment(
                              appointment.start,
                              "YYYY-MM-DD hh:mm:ss"
                            ).format("H:mm")}
                          </p>
                        </strong>
                      </td>
                      <td style={{ border: "none" }} className="w-25">
                        
                      </td>
                      <td style={{ border: "none" }} className="w-25">
                        <p className="font-weight-bold">{appointment.name}</p>
                        <p>
                          {appointment.address +
                            " " +
                            appointment.city +
                            " " +
                            appointment.postal_code}
                        </p>
                        <p>{appointment.phone}</p>
                        <p>{appointment.email}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ))}
      </Scrollable>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings ? state.settings : {},
  account: state.account ? state.account : null,
  group: state.group ? state.group : null,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentTable);
