import { API } from "../api";

const checkUrl = (code) => {
  return API.get(
    "unauthorized/portal/authentication/check-url?code=" + code
  ).then((response) => {
    console.log(response);
    return response;
  });
};

const confirmAccount = (code, info, status) => {
  return API.post(
    "unauthorized/portal/authentication/register-info?code=" + code,
    { data: info, status: status }
  )
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((err) => { });
};

const confirmEmail = (hash) => {
  return API.post(
    "unauthorized/portal/authentication/confirm-registration?hash=" + hash
  )
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((err) => { });
};

const deleteConnectedPatient = ($patientId) => {
  return API.post(
    `account/account/account/remove-connected-patient?id=${$patientId}`
  ).then((response) => {
    return response;
  });
};

const deleteConnection = ($groupId) => {
  return API.post(
    `account/account/account/remove-connected-group?id=${$groupId}`
  ).then((response) => {
    return response;
  });
};

const getAccountInfo = (id = null) => {
  let url = "account/account/account/get-account-info";
  if (id) {
    url = url + "?id=" + id;
  }
  return API.get(url).then((response) => {
    return response;
  });
};

const getAppointments = () => {
  return API.get("account/account/account/get-appointments").then(
    (response) => {
      return response;
    }
  );
};

const getConnectedGroups = () => {
  return API.get("account/account/account/get-connected-groups").then(
    (response) => {
      return response;
    }
  );
};

const getConnectedPatients = () => {
  return API.get("account/account/account/get-connected-patients").then(
    (response) => {
      return response;
    }
  );
};

const getConnectionRequests = () => {
  return API.get("account/account/account/get-connection-requests").then(
    (response) => {
      return response;
    }
  );
};

const getLinkedPatientAppointments = () => {
  return API.get(
    "account/account/account/get-appointments-linked-patients"
  ).then((response) => {
    return response;
  });
};

const getProofFile = (id) => {
  return API.get(`account/account/account/get-proof-file?id=${id}`, "", {
    "Content-Type": "application/pdf",
  }).then((response) => {
    return response;
  });
};

const getUserConnectionRequests = () => {
  return API.get(`account/account/account/get-user-connection-requests`).then(
    (response) => {
      return response;
    }
  );
};

const logout = () => {
  return API.get("account/account/account/logout");
};

const readEid = () => {
  return API.get(process.env.REACT_APP_EID_READER_ENDPOINT.trim() + "/eid")
    .then(response => {
      return response;
    });
};

const requireEidPin = () => {
  return API.get(process.env.REACT_APP_EID_READER_ENDPOINT.trim() + "/eid/pin").then((response) => {
    return response;
  });
};

const submitGroupConnectionRequest = (groupId) => {
  return API.post(
    `account/account/account/submit-request?groupId=${groupId}`
  ).then((response) => {
    return response;
  });
};

const submitUserConnectionRequest = (groupId, isAdult, cardData) => {
  return API.post(
    `account/account/account/submit-user-connection-request?groupId=${groupId}&adult=${isAdult}`,
    { cardData: cardData }
  )
    .then((response) => {
      return response;
    })
    .catch((err) => { });
};

const updateAccountInfo = (info, id = null) => {
  let url = "account/account/account/update-account-info";
  if (id) {
    url = url + "?id=" + id;
  }
  return API.post(url, {
    data: info,
  })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((err) => { });
};

export const ApiAccountService = {
  checkUrl,
  confirmAccount,
  confirmEmail,
  deleteConnectedPatient,
  deleteConnection,
  getAccountInfo,
  getAppointments,
  getConnectedGroups,
  getConnectedPatients,
  getConnectionRequests,
  getLinkedPatientAppointments,
  getProofFile,
  getUserConnectionRequests,
  logout,
  readEid,
  requireEidPin,
  submitGroupConnectionRequest,
  submitUserConnectionRequest,
  updateAccountInfo,
};
