import React, { Component } from "react";
import { Card, CardHeader, CardBody, Button, Input } from "reactstrap";
import { t } from "i18next";
import Scrollable from "./Scrollable";

class SearchGroup extends Component {
  handleGroupChange = (group) => {
    this.props.onSelectGroup(group);
  };

  render() {
    const { modalInput, data, onChangeModalInput } = this.props;

    return (
      <Card className="card-default">
        <CardHeader>{t("common:Search")}</CardHeader>
        <CardBody>
          <div className="input-group">
            <Input
              className="form-control"
              type="text"
              placeholder={t("account.group:search")}
              value={modalInput}
              onChange={onChangeModalInput}
            />
            <span className="input-group-btn">
              <Button
                color="default"
                type="button"
                onClick={onChangeModalInput}
              >
                <em className="fa fa-search" />
              </Button>
            </span>
          </div>
          <div>
            {data.length > 0 && modalInput.length !== 0 ? (
              <Scrollable height="500" className="list-group">
                <table
                  className="table table-borderedless"
                  style={{ tableLayout: "fixed" }}
                >
                  <tbody>
                    {data.map((group, i) => (
                      <tr key={i}>
                        <div
                          style={{
                            backgroundColor: "#e2edff",
                            marginBottom: "10px",
                            marginTop: "10px",
                            borderRadius: "7px",
                          }}
                          className="d-flex justify-content-start"
                        >
                          <td style={{ border: "none" }} className="w-25">
                            <strong>
                              <p>{group.name}</p>
                            </strong>
                          </td>
                          <td style={{ border: "none" }} className="w-25">
                            <p>
                              {group.address +
                                ", " +
                                group.postal_code +
                                " " +
                                group.city}
                            </p>
                            <p>{group.phone}</p>
                            <p>{group.email}</p>
                          </td>
                          <td style={{ border: "none" }} className="w-50">
                            <Button
                              color="primary"
                              className="float-right"
                              onClick={() => this.handleGroupChange(group)}
                            >
                              <em className="fa fa-angle-right" />
                            </Button>
                          </td>
                        </div>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scrollable>
            ) : (
              <Scrollable height="0" />
            )}
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default SearchGroup;
