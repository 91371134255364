import { API } from "../api";

const getByClient = () => {
  return API.get("client/group/group/get-by-client").then((response) => {
    if (API.isSuccess(response)) {
      return response.data.groups;
    }

    throw response.data;
  });
};

const getActiveGroupClient = () => {
  return API.get("client/group/group/get-active-group").then((response) => {
    if (API.isSuccess(response)) {
      return response.data.group;
    }

    return {};
  });
};

const searchGroups = (searchStr) => {
  return API.get("account/group/group/find-group?search=" + searchStr).then(
    (response) => {
      if (API.isSuccess(response)) {
        return response.data;
      }
      return {};
    }
  );
};

export const ApiGroupService = {
  getByClient,
  getActiveGroupClient,
  searchGroups,
};
