import { withRouter } from "react-router";
import axios from "axios";
import i18next from "i18next";
import configureStore from "../store/store";
import { UtilApi } from "../utils/api.util";
import { UtilUrl } from "../utils/url.util";
import routes from "../routing/routes";

let authToken = null;
const setToken = (token) => {
  authToken = token;
};

let sessionId = null;
const setSessionId = (id) => {
  sessionId = id;
};

/*const state = () => {
    try {
        return configureStore().getState()
    } catch (error) {
        //console.log(error);
    }
};*/

const state = configureStore().getState();

const client = axios.create({
  // baseURL: process.env.REACT_APP_API_HOST + '/' + process.env.REACT_APP_API_VERSION + '/',
  baseURL: process.env.REACT_APP_API_HOST.trim() + "/",
  headers: {
    "Content-Type": "application/json",
    "X-Session": state.settings.sessionId,
  },
});

const get = (url, params) => {
  return request({
    url: url,
    method: "GET",
    params: params,
    headers: {},
  });
};

const remove = (url, params) => {
  // console.log(url);
  return request({
    url: url,
    method: "DELETE",
    params: params,
    headers: {},
  });
};

const post = (url, data, headers = {}) => {
  // console.log(data);
  return request({
    url: url,
    method: "POST",
    data: data,
    headers: headers,
  });
};

const request = (requestOptions) => {
  UtilApi.cancelRequest(requestOptions.url);

  UtilApi.addCancelToken(requestOptions.url);

  if (!authToken) {
    authToken = state.account ? state.account.access_key : null;
  }

  delete client.defaults.headers.common["Authorization"];
  if (authToken) {
    client.defaults.headers.common["Authorization"] = authToken;
  }

  if (!sessionId) {
    sessionId = state.settings.sessionId ? state.settings.sessionId : null;
  }

  delete client.defaults.headers.common["X-Session"];
  if (sessionId) {
    client.defaults.headers.common["X-Session"] = sessionId;
  }

  if (requestOptions["headers"]["Content-Type"]) {
    client.defaults.headers.common["Content-Type"] =
      requestOptions["headers"]["Content-Type"];
  }

  if (requestOptions["data"]) {
    requestOptions["data"]["lng"] = i18next.language;
  }

  if (requestOptions["params"]) {
    requestOptions["params"]["lng"] = i18next.language;
  }

  client.defaults.cancelToken = UtilApi.getCancelToken(requestOptions.url);

  // console.log(requestOptions);

  return client(requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        // console.log('Request aborted: ' + error);
        return "canceled";
      } else if (error.response) {
        const { status, statusText } = error.response;

        // console.log(status);
        if (status === 401) {
          // console.log(error.response);
          // TODO: Show unauthorized message
          // TODO: Check if token is valid; if not redirect to login
          return error.response.data;
        } else if (status === 403) {
          throw error;
          //window.location = UtilUrl.generate(routes.group.decisionTree);
        } else if (status === 404) {
          window.location = UtilUrl.generate(routes.notfound);
        } else if (status === 500) {
          // LocalStorageService.store('FlashMessage', {type: 'error', data: 'An error occured'});
          // window.location = routes.error;
        } else {
          console.error(status, statusText, error);
        }
      } else {
        throw error;
      }
    })
    .then((response) => {
      UtilApi.removeCancelToken(requestOptions.url);

      return response;
    });
};

const isSuccess = (response) => {
  return response.status && response.status === "success";
};

export const API = withRouter({
  get,
  post,
  remove,
  isSuccess,
  setToken,
  setSessionId,
});
