import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApiGroupService } from "../../services/api/group.service";
import { t } from "i18next";
import * as actions from "../../store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { ApiAccountService } from "../../services/api/account.service";
import SearchGroup from "../SearchGroup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class NewConnectionRequestModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      modalOpen: false,
      modalInput: "",
      selectedGroup: null,
      errors: [],
      status: "",
    };
  }

  showModal() {
    this.setState({ modalOpen: true });
  }

  hideModal() {
    this.setState({ modalOpen: false });
  }

  onChangeModalInput = (e) => {
    this.setState({
      modalInput: e.target.value,
    });

    ApiGroupService.searchGroups(this.state.modalInput).then((res) => {
      this.setState({ data: res });
    });
  };

  setGroup = (group) => {
    this.setState({ selectedGroup: group });
  };

  deselectGroup() {
    this.setState({ selectedGroup: null, status: "" });
  }

  showToast() {
    if (this.state.status === "success") {
      toast("Aanvraag succesvol verzonden", {
        type: "success",
        position: toast.POSITION.TOP_RIGHT,
      });
      this.hideModal();
    }
    if (this.state.status === "fail") {
      toast(this.state.errors[0], {
        type: "error",
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  async submitRequest() {
    console.log(this.state.selectedGroup.id);
    await ApiAccountService.submitGroupConnectionRequest(
      this.state.selectedGroup.id
    ).then((res) => {
      this.setState({ status: res.status });
      if (res.status === "fail") {
        this.setState({ errors: res.data });
      }
    });
    this.showToast();
  }

  render() {
    const { selectedGroup } = this.state;

    return (
      <>
        <button className="btn btn-primary " onClick={() => this.showModal()}>
          <div className="d-flex justify-content-center align-items-center">
            <em className="fa fa-plus mr-2 m-0 p-0" />
            <span className="m-0 p-0">Praktijk toevoegen</span>
          </div>
        </button>
        <Modal className="sm" isOpen={this.state.modalOpen}>
          <ModalHeader>{t("account.group:add")}</ModalHeader>
          {this.state.selectedGroup === null ? (
            <>
              <ModalBody>
                <SearchGroup
                  data={this.state.data}
                  onChangeModalInput={this.onChangeModalInput}
                  modalInput={this.state.modalInput}
                  onSelectGroup={this.setGroup}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="default" onClick={() => this.hideModal()}>
                  {t("common:Cancel")}
                </Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalBody>
                <Card className="card-default">
                  <CardHeader>
                    <h2>{selectedGroup.name}</h2>
                  </CardHeader>
                  <CardBody>
                    <p>{selectedGroup.address}</p>
                    <p>{selectedGroup.city}</p>
                    <p>{selectedGroup.phone}</p>
                    <p>{selectedGroup.email}</p>
                  </CardBody>
                </Card>
              </ModalBody>
              <ModalFooter>
                <Button color="default" onClick={() => this.deselectGroup()}>
                  Terug
                </Button>
                <Button color="primary" onClick={() => this.submitRequest()}>
                  Aanvraag indienen
                </Button>
              </ModalFooter>
            </>
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  account: state.account,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewConnectionRequestModal);
