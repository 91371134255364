import { TOGGLE_SETTING, CHANGE_SETTING } from './settings.actions';

const initialSettings = {
    /* Nr of agendas next to each other in group overview */
    defaultLanguage: 'nl',
    language: 'nl',
    sessionId: null,
    extraParam: null,
    guid: null
};

const settingsReducer = (state = initialSettings, action) => {
    switch (action.type) {
        case TOGGLE_SETTING:
            return {
                ...state,
                [action.name]: !state[action.name]
            }
        case CHANGE_SETTING:
            return {
                ...state,
                [action.name]: action.value
            };
        default:
            return state;
    }
}

export default settingsReducer;