import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions";
import HeaderRun from "./Header.run";
import { UtilUrl } from "../../utils/url.util";
import routes from "../../routing/routes";
import DropdownLanguageSelect from "../dropdown/DropdownLanguageSelect";
import { t } from "i18next";
import DropdownProfile from "../dropdown/DropdownProfile";

class Header extends Component {
  componentDidMount() {
    HeaderRun();
  }

  toggleUserblock = (e) => {
    e.preventDefault();
    this.props.actions.toggleSetting("showUserBlock");
  };

  resize() {
    // all IE friendly dispatchEvent
    var evt = document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  }

  logout = (e) => {
    e.preventDefault();

    this.props.actions.accountAccessKeyRemove();

    this.redirect();
  };

  redirect = () => {
    this.props.actions.accountLoggedOut();
    this.props.history.push(routes.auth.login);
  };

  toggleAside = (e) => {
    e.preventDefault();
    this.props.actions.toggleSetting("asideToggled");
  };

  render() {
    const { account } = this.props;

    return (
      <header className="topnavbar-wrapper">
        {/* START Top Navbar */}
        <nav className="navbar topnavbar">
          {/* START navbar header */}

          {/* END navbar header */}

          {/* START Left navbar */}
          <ul className="navbar-nav mr-auto flex-row">
            <div
              className="pl-3 pt-1 pr-4"
              onClick={() =>
                this.props.history.push(
                  UtilUrl.generate(routes.appointmentoverview)
                )
              }
              style={{ cursor: "pointer" }}
            >
              <div className="brand-logo">
                <img
                  className="img-fluid"
                  src="images/mya-logo-white.svg"
                  alt="MYA logo"
                />
              </div>
            </div>
            <li className="nav-item">
              {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <a
                href=""
                className="nav-link sidebar-toggle d-md-none"
                onClick={this.toggleAside}
              >
                <em className="fas fa-bars" />
              </a>
            </li>
            {this.props.account && (
              <>
                <li className="nav-item d-none d-md-block">
                  <a
                    href={UtilUrl.generate(routes.appointmentoverview)}
                    className="nav-link"
                  >
                    <em className="icon-calendar mr-1" />
                    {/* {t("account.appointment:title")} */}
                    Mijn afspraken
                  </a>
                </li>
                <li className="nav-item d-none d-md-block">
                  <a
                    href={UtilUrl.generate(routes.groupoverview)}
                    className="nav-link"
                  >
                    <em className="far fa-building mr-1" />
                    Mijn praktijken
                  </a>
                </li>
                <DropdownProfile />
              </>
            )}
          </ul>
          {/* END Left navbar */}
          {/* START Right Navbar */}
          <ul className="navbar-nav flex-row">
            {this.props.account ? (
              <>
                <li className="nav-item d-none d-md-block">
                  <span className="navbar-text nav-text">
                    {t("header.welcome", {
                      name:
                        account.data.firstName + " " + account.data.lastName,
                    })}
                  </span>
                </li>
              </>
            ) : (
              <li className="nav-item d-none d-md-block">
                <a
                  href={UtilUrl.generate(routes.auth.login)}
                  className="nav-link"
                >
                  <em className="icon-user mr-1" />
                  Login
                </a>
              </li>
            )}

            <DropdownLanguageSelect />
            {/* START logout */}

            {this.props.account && (
              <React.Fragment>
                {/*<li className="nav-item d-none d-md-block">
                                    <Link to={{ pathname: UtilUrl.generate(routes.account.overview) }} title="Logout" className="nav-link">
                                        <em className="icon-user"></em>
                                    </Link>
                                </li>*/}

                <li className="nav-item d-md-block">
                  <a className="nav-link" href="#" onClick={this.logout}>
                    <em className="icon-logout" />
                  </a>
                </li>
              </React.Fragment>
            )}
            {/* END logout */}
          </ul>
          {/* END Right Navbar */}
        </nav>
        {/* END Top Navbar */}
      </header>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({
  settings: state.settings ? state.settings : {},
  account: state.account ? state.account : null,
  group: state.group ? state.group : null,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
